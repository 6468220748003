import React, { useState } from "react";
import { createContext } from "react";

export const StateContext = createContext(null);

function StateContextProvider({ children }) {
    const [tankHandleDetails,setTankHandleDetails] = useState([])
  console.log(tankHandleDetails)

  return (
    <div>
      <StateContext.Provider
        value={{
            tankHandleDetails, setTankHandleDetails
        }}
      >
        {children}
      </StateContext.Provider>
    </div>
  );
}

export default StateContextProvider;
