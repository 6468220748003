import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constant";

export const PumpDetailsContext = createContext(null);

function PumpDetailsProvider({ children }) {
  const privileges = secureLocalStorage.getItem('privileges')
  const userId = secureLocalStorage.getItem('userID')
  const plantId = secureLocalStorage.getItem('plant_id')
  console.log(plantId)
  const [pumpDetails , setPumpDetails] = useState(null)
  console.log(pumpDetails)

  // isPumpActive is for only pump
  const [isPumpActive,setIsPumpActive] = useState()
  console.log(isPumpActive)

  const [sidebarPumps, setSidebarPumps] = useState([])
  console.log(sidebarPumps)

  // provision for "super admin" and "admin" hierechy => Specific pump for specific plant id 
  // After clicking on plant the "id" of plant gets passed below and specific pump for that plant gets populated
  const apiPumpDetails = async () => {
    try {
      console.log("HIIII")
      // setIsLoading(true)
      const url = VARIABLES.url + '/api/entity-details?type=pump&parent_id='+plantId
      console.log(url)
      const res = await axios.get(url);
      // if(res.data.length === 0) {
      //   setSidebarPumps(res.data)
      //   console.log(res.data)
      //   props.setPumpDetails(null);
      // } else {
      setSidebarPumps(res.data)
      console.log(res.data)
      setPumpDetails(res.data[0].id);
      console.log(res.data[0].id);
      // setIsPumpActive(res.data[0].name)
      // setIsActive("")
      // }
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // provision for plant owner hierechy => Specific pump for specific plantowner
  const apiPumpPlantOwnerDetails = async (userId) => {
    try {
      // setIsLoading(true)
      const url = VARIABLES.url + '/api/entity-details?type=pump&user_id='+userId
      const res = await axios.get(url);
      setSidebarPumps(res.data)
      console.log(res.data[0].id)
      setPumpDetails(res.data[0].id);
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  

  return (
    <div>
      <PumpDetailsContext.Provider
        value={{ setSidebarPumps, 
        sidebarPumps,
        pumpDetails,
        setPumpDetails,
        isPumpActive,
        apiPumpDetails,
        apiPumpPlantOwnerDetails,
        setIsPumpActive
         }}>
        {children}
      </PumpDetailsContext.Provider>
    </div>
  );
}

export default PumpDetailsProvider;
