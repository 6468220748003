import React from "react";
import { useContext } from "react";
import { useState, useEffect } from "react";
import Paho from 'paho-mqtt';
import { VARIABLES } from '../Constant';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(true);

  const [pumpChecked, setPumpChecked] = useState(null);
  console.log(pumpChecked)

  const [mqttClient, setMqttClient] = useState(null)
  console.log(mqttClient)

  const [formatted, setFormatted] = useState({});
  console.log(formatted)

  const getMqttConnection = () => {
    console.log(`REACT-${VARIABLES.mqtt_client_test}-MQTT`)
    // Replace with your MQTT broker URL
    const client = new Paho.Client('wss://mqtt.elorca.com:9001/mqtt', `REACT-${uuidv4()}-MQTT`);
    // const client = new Paho.Client('mqtt.elorca.com', 9001, 'REACT-1-MQTT');
    client.debug = true;
    client.onConnectionLost = (responseObject) => {
      if (responseObject.errorCode !== 0) {
        console.log(`Connection lost: ${responseObject.errorMessage}`);
        setIsConnected(false);
        // Attempt to reconnect
        // client.connect();
      }
    };
    client.connect({
      keepAliveInterval: 60,  // in seconds
      onSuccess: () => {
        console.log('Connected to MQTT broker');
        client.subscribe('+/+/+/+/feedback');
        setMqttClient(client)
        setIsConnected(true);
      },
      useSSL: true, // if your broker requires SSL
      // reconnect: true, // enable automatic reconnection
      onFailure: (error) => {
        console.error(`Connection failed - ErrorCode: ${error.errorCode}, ErrorMessage: ${error.errorMessage}, Stack: ${error.stack}`);
        setIsConnected(false);
      },
    });

    client.onMessageArrived = (message) => {
      console.log(message.payloadString)
      const split_entity_data = message.destinationName.split('/');
      console.log(split_entity_data);
      const feedback_value = parseInt(message.payloadString);

      setFormatted((prevData) => {
        const newData = { ...prevData };

        let temp = newData;

        for (let i = 0; i < split_entity_data.length - 2; i++) {
          const mqtt_data = split_entity_data[i];
          temp[mqtt_data] = temp[mqtt_data] || {};
          temp = temp[mqtt_data];
        }

        const pin = split_entity_data[split_entity_data.length - 2];
        temp[pin] = temp[pin] || 0;
        temp[pin] = feedback_value;

        return { ...newData };
      });
    };

    return () => {
      if (client.isConnected())
        client.disconnect();
      setIsConnected(false);
    }
  }

  useEffect(() => {
    getMqttConnection()
  }, []);

  return <AppContext.Provider value={{ formatted, setPumpChecked, pumpChecked, setFormatted, mqttClient, isConnected, setIsConnected, getMqttConnection }}>{children}</AppContext.Provider>
}
const useGlobalContext = () => {
  return useContext(AppContext);
}
export { AppContext, AppProvider, useGlobalContext };