import React, { useEffect, useState, useContext } from 'react'
import PumpManagementCreate from '../PumpManagement/PumpManagementCreate'
import PumpManagementEdit from '../PumpManagement/PumpManagementEdit'
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import secureLocalStorage from "react-secure-storage";
import PumpRender from './PumpRender';
import TankDetails from './TankDetails';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const PumpDetails = (props) => {
  const {
    setPumpDetails, pumpDetails, setSidebarPumps } = useContext(PumpDetailsContext);
  // Local Storage Saved values. 
  const privileges = secureLocalStorage.getItem('privileges')
  const userId = secureLocalStorage.getItem('userID')
  const adminId = secureLocalStorage.getItem('admin_id')
  const plantId = secureLocalStorage.getItem('plant_id')
  const superior_id = secureLocalStorage.getItem('superior_id')
  console.log(superior_id)

  // isLoading state for data loading
  const [isLoading, setIsLoading] = useState(false)

  // stored plantowner details in "userdetails" to pass as dropdown in pump create/edit form
  const [userdetails, setUserDetails] = useState([]);

  // edited details for pumps gets stored here
  const [editedPump, setEditedPump] = useState(null)
  console.log(editedPump)

  // After selecting the node the node value stored in "selectedNode" state
  const [selectedNode, setSelectedNode] = useState([])
  console.log(selectedNode)

  // "plantDetails" variable store plant (id and label) as {value , label} to pass in dropdown 
  const [plantDetails, setPlantDetails] = useState([])
  console.log(plantDetails)

  // Stored pump data in "pumps" state 
  const [pumps, setPumps] = useState([])
  console.log(pumps)

  // Stored node name in "nodeData" in {label and value format}
  const [nodeData, setNodeData] = useState([])
  console.log(nodeData)

  // Raw data of (node and pins avaliable and unavaliable)
  const [nodePinData, setNodePinData] = useState([])
  console.log(nodePinData)

  // options to be send in React Select dropdown for pins
  const [options, setOptions] = useState([])

  // To switch form one page to another
  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // API call to get plantowner details stored in "userdetails" variable (dropdown)
  const apiUserDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/userdetails?role=plant owner');
      setUserDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.user.username
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "super admin" and "admin" hierechy => Specific pump for specific plant id 
  // After clicking on plant the "id" of plant gets passed below and specific pump for that plant gets populated
  const apiPumpDetails = async () => {
    try {
      setIsLoading(true)
      const url = VARIABLES.url + '/api/entity-details?type=pump&parent_id=' + plantId
      console.log(url)
      const res = await axios.get(url);
      if (res.data.length !== 0) {
        setPumps(res.data)
        console.log(res.data)
        setIsLoading(false)
        setSidebarPumps(res.data)
        console.log(res.data)
        setPumpDetails(res.data[0].id);
      } else {
        setPumps(res.data)
        console.log(res.data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  // provision for plant owner hierechy => Specific pump for specific plantowner
  const apiPumpPlantOwnerDetails = async () => {
    try {
      setIsLoading(true)
      const url = VARIABLES.url + '/api/entity-details?type=pump&user_id=' + userId
      const res = await axios.get(url);
      setPumps(res.data)
      secureLocalStorage.setItem('pump_id', res.data[0].id);
      setSidebarPumps(res.data)
      console.log(res.data[0].id)
      setPumpDetails(res.data[0].id);
      setIsLoading(false)
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "super admin" plantDetails in dropdown 
  const apiPlantDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + adminId);
      setPlantDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "admin" Login for plantDetails in dropdown 
  const apiAdminDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + userId);
      setPlantDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // provision for "Plantowner" Login for plantDetails in dropdown 
  const apiPlantOwnerDropdownDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/entity-details?type=plant&user_id=' + superior_id);
      setPlantDetails(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Api call to get Node Id 
  const apiNodeData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/node-details');
      setNodePinData(res.data)
      setNodeData(res.data.map((node) => ({
        value: node.name,
        label: node.name
      })))
      console.log(res.data)
    } catch (error) {
      console.log(error);
    }
  };

  // to get specific pin of selected node
  const getNodePin = () => {
    if (selectedNode.length === 0) {
      console.log("hi")
    } else {
      const selectedPins = nodePinData.find((pin) => pin.name === selectedNode.label)
      console.log(selectedPins)
      const availableOptions = selectedPins.output_pin_available.map((value) => ({
        value: value,
        label: value,
        isDisabled: false, // output_pin_available should be enabled
      }));

      const unavailableOptions = selectedPins.output_pin_unavailable.map((value) => ({
        value: value,
        label: value,
        isDisabled: true, // output_pin_unavailable should be disabled
      }));
      setOptions(availableOptions.concat(unavailableOptions))
    }
  }

  // Function to get specific id details and stored in state "editedPump"
  // const editPump = (id) => {
  //   const getEditedPump = pumps.find((pump) => {
  //     return pump.id === id
  //   })
  //   console.log(getEditedPump);
  //   setEditedPump(getEditedPump);
  // }

  const [filterPumpWithPumpId, setFilterPumpWithPumpId] = useState([])
  console.log(filterPumpWithPumpId)

  useEffect(() => {
    console.log(pumps)
    if (pumps.length > 0) {
      console.log(pumps)
      const filter_pump_with_pump_id = pumps.filter((pump) => {
        console.log(pump.id)
        console.log(pumpDetails)
        return pump.id === pumpDetails
      })
      console.log(filter_pump_with_pump_id)
      setFilterPumpWithPumpId(filter_pump_with_pump_id)
    }
  }, [pumps, pumpDetails, plantId])

  useEffect(() => {
    apiUserDetails();
    apiNodeData();
    getNodePin();
    // apiPinNumber();
    { privileges.includes("superadmin_admin") && apiPumpDetails() }  //admin and super admin
    { privileges.includes("plantowner") && apiPumpPlantOwnerDetails() } // plant owner 
    { privileges.includes("superadmin") && apiPlantDropdownDetails() } // only for superadmin => for plant dropdown
    { privileges.includes("admin") && apiAdminDropdownDetails() } // only for admin => plant dropdown
    { privileges.includes("plantowner") && apiPlantOwnerDropdownDetails() } // only for plantowner => plant dropdown
  }, [plantId, selectedNode])

  return (
    <>
      {privileges.includes("back_plant") && <div className='nk-block-head' style={{ paddingBottom: "1.25rem" }}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={() => onChangeSidebar("Plant Overview")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={() => onChangeSidebar("Plant Overview")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}

      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="plantowner nk-block-title page-title">Welcome to Client Dashboard</h3>
            <div className="nk-block-des text-soft">Pumps's Overview</div>
          </div>
          {/* <!-- .nk-block-head-content --> */}
          <div class="nk-block-head-content">
            <div className="col-auto d-flex w-sm-100">
              {privileges.includes("add_pump") && <button type="button" className="btn btn-primary me-3" data-bs-toggle="modal" data-bs-target="#addPump"><em class="icon ni ni-plus"></em><span>Create Pump</span></button>}
              {privileges.includes("add_tank") && <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createTank"><em class="icon ni ni-plus"></em><span>Create Tank</span></button>}
            </div>
          </div>
        </div>
      </div>

      <div className='row mx-0'>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          {isLoading ? <div className='card-inner'>Loading...</div> :
            <>
              {pumps.length === 0 &&
                <div className='row'>
                  <div className='col-sm-12' style={{ marginTop: "20px" }}>
                    <div className='card'>
                      <div className='card-inner'>
                        Pump are not created
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                filterPumpWithPumpId.map((pump, index) => {
                  return <PumpRender pump={pump}
                    pumpDetails={pumpDetails}
                    isLoading={isLoading}
                    editedPump={editedPump}
                    // editPump={editPump}
                    handleSidebar={props.handleSidebar}
                    setPumps={setPumps}
                    pumps={pumps}
                    setSidebarPumps={props.setSidebarPumps}
                    sidebarPumps={props.sidebarPumps}
                    setSchedularZone={props.setSchedularZone}
                    setEditedPump={setEditedPump}
                  />
                })
              }
            </>
          }
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <TankDetails handleSidebar={props.handleSidebar} />
        </div>
      </div>

      <PumpManagementCreate userdetails={userdetails}
        plantDetails={plantDetails}
        apiPumpDetails={apiPumpDetails}
        apiPumpPlantOwnerDetails={apiPumpPlantOwnerDetails}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options} />

      <PumpManagementEdit userdetails={userdetails}
        plantDetails={plantDetails}
        editedPump={editedPump}
        apiPumpDetails={apiPumpDetails}
        apiPumpPlantOwnerDetails={apiPumpPlantOwnerDetails}
        nodeData={nodeData}
        selectedNode={selectedNode}
        setSelectedNode={setSelectedNode}
        options={options} />
    </>
  )
}

export default PumpDetails