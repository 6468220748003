// Water Tank Notification 
// All Logs Of Tanks and here on CLicking View Button
import React from 'react'
import secureLocalStorage from 'react-secure-storage'

const WaterTankNotification = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }
  return (
    <>
      <div className="row gy-0" style={{ marginTop: "25px" }}>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card h-100">
            <div className="nk-ecwg nk-ecwg1">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">Tank Switching Log</h6>
                  </div>
                  {privileges.includes("tank_logs") && <div className="card-tools">
                    <a href="javascript:void(0)" className="link card-link"
                      onClick={() => onChangeSidebar("Tank Status")}
                    >View All</a>
                  </div>}
                </div>
                <div className="data">
                  <div className="amount"> 2 </div>
                  <div className="info"><strong>count</strong></div>
                </div>
              </div>
              {/* <div className="card-inner">
              <div className="timeline">
                <h6 className="timeline-head">Today's</h6>
                <ul className="timeline-list">
                  <li className="timeline-item">
                    <div className="timeline-status bg-primary is-outline"></div>
                    <div className="timeline-date">10:05am <em className="icon ni ni-alarm-alt"></em></div>
                    <div className="timeline-data">
                      <h6 className="timeline-title">Water Tank 1</h6>
                      <div className="timeline-des">
                        <p>Water Tank Empty</p>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-status bg-primary"></div>
                    <div className="timeline-date">12:45pm <em className="icon ni ni-alarm-alt"></em></div>
                    <div className="timeline-data">
                      <h6 className="timeline-title">Water Tank 1</h6>
                      <div className="timeline-des">
                        <p>Water Tank Fulled</p>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-item">
                    <div className="timeline-status bg-pink"></div>
                    <div className="timeline-date">3:15pm <em className="icon ni ni-alarm-alt"></em></div>
                    <div className="timeline-data">
                      <h6 className="timeline-title">Water Tank 1</h6>
                      <div className="timeline-des">
                        <p>Water Tank Fulled</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            </div>
          </div>
          {/* <!-- .card --> */}
        </div>
        {/* <!-- .col --> */}
      </div>
    </>
  )
}

export default WaterTankNotification