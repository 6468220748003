import React from 'react'
import { useState, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from '../../Constant';
import Swal from 'sweetalert2';
import axios from 'axios'
import moment from 'moment';
import Paho from 'paho-mqtt';
import DeleteModalZone from '../Modals/DeleteModalZone';
import { useGlobalContext } from '../Context';
// pumpDetailsContext missing

const ZoneRender = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')

  
  const [deleteZoneResponse,setDeleteZoneResponse]=useState(null)
  const [userId,setUserId]=useState(null)
  console.log(userId)

  const { setFormatted,formatted, mqttClient, setIsConnected } = useGlobalContext();
  const [checked,setChecked] = useState(null);

  const [toggleZoneLoader,setToggleZoneLoader] = useState(false)

  const [lastZoneFeedbackTime, setLastZoneFeebbackTime] = useState()

  const handleChange = (event, mqtt_topic) => {
    if (event === true) {
      Swal.fire({
        title: 'Are you sure? You want turn on zone',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const url = VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${mqtt_topic}&is_on=${event}`
          console.log(url)
          var config = {
            method: 'get',
            // url: VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${VARIABLES.subdomain}/${VARIABLES.project_id}/${VARIABLES.node_id}/P_D4/values&is_on=${val}`,
            url: url,
            headers: {}
          };

          axios(config)
            .then((response) => {
              // setToggleZoneLoader(true)
              const response_message = response.data
              console.log(JSON.stringify(response.data));
              // setToggleZoneLoader(false)
            })
            .catch((error) => {
              console.log(error);
              // setToggleZoneLoader(false)
            });
        }
      })
    }
    if (event === false) {
      Swal.fire({
        title: 'Are you sure? You want turn off zone',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // debugger
          // setChecked(event)
          // setToggleZoneLoader(true)
          const url = VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${mqtt_topic}&is_on=${event}`
          console.log(url)
          var config = {
            method: 'get',
            // url: VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${VARIABLES.subdomain}/${VARIABLES.project_id}/${VARIABLES.node_id}/P_D4/values&is_on=${val}`,
            url: url,
            headers: {}
          };

          axios(config)
            .then((response) => {
              // setToggleZoneLoader(true)
              const response_message = response.data
              console.log(JSON.stringify(response.data));
              // setToggleZoneLoader(false)
            })
            .catch((error) => {
              console.log(error);
              // setToggleZoneLoader(false)
            });
        }
      })
    }
  }

  const onChangeSidebar = (section, zone) => {
    props.handleSidebar(section)
    props.setSchedularZone(zone)
  }
  
  const apiZoneFeedback = async () => {
    try {
      const url = VARIABLES.url + '/api/current-entity-status?entity_id=' + props.zone.id
      const res = await axios.get(url);
      console.log(res.data);
      const api_data = res.data?.[0]?.entity.mqtt_topic
      const feedback_value = parseInt(res.data[0].entity_status);
      setLastZoneFeebbackTime(moment(res.data?.[0]?.config_last_updated).format('MMMM Do YYYY, hh:mm a'));
      updatedZoneAPI(api_data,feedback_value)
    } catch (error) {
      console.log(error);
    }
  }; 
  
  const updatedZoneAPI = (api_data,feedback_value) => {
    const split_entity_data = api_data.split('/');

    setFormatted((prevData) => {
      let newData = { ...prevData };

      let temp = newData;

      for (let i = 0; i < split_entity_data.length - 2; i++) {
        const mqtt_data = split_entity_data[i];
        temp[mqtt_data] = temp[mqtt_data] || {};
        temp = temp[mqtt_data];
      }

      const pin = split_entity_data[split_entity_data.length - 2];
      temp[pin] = temp[pin] || 0;
      temp[pin] = feedback_value;

      return { ...newData };
    });
  }

  const deleteZone = () => {
    const delete_user_id = secureLocalStorage.getItem("delete_user_id")
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${delete_user_id.id}&type=entity`,
      headers: {}
    };
    axios(config).then((response) => {
      Swal.fire({
        text: 'Zone Deleted Successfully!',
        icon: 'success'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModalZone').click();
          props.setZones(props.zones.filter((user) => {
            console.log(user)
            return user.id !== userId?.id
          }));
          props.getNodePin()
          props.apiNodeData()
          updateHandler()
        }
      })
      console.log(JSON.stringify(response.data));
      // window.location.reload();
    })
      .catch((error) => {
        Swal.fire({
          text: 'Failed to Delete Zone',
          icon: 'warning'
        })
        console.log(error);
      });
  };

  const deleteZoneAlert = async(data) => {
    console.log(data)
    try {
      const res = await axios.get(VARIABLES.url+"/api/delete-confirmation?id="+data.id+"&type=entity");
      setUserId(data)
      secureLocalStorage.setItem("delete_user_id", data)
      document.getElementById('zonemodalDelete').click();
      console.log(res.data)
      setDeleteZoneResponse(res.data)
    } catch (error) {
      console.log(error);
    }

  };

  const updateHandler = () => {
    const delete_user_id = secureLocalStorage.getItem("delete_user_id")
    const url = VARIABLES.url + '/api/mqtt-json?node_id=' + delete_user_id.node_id
    console.log(url)
    var config = {
      method: 'get',
      url: url,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          icon: "success",
          title: "Scheduler Updated Successfully",
          showConfirmButton: false,
          timer: 2000
        })
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update Scheduler',
          showConfirmButton: false,
          timer: 2000
        })
      });
  }

  const updateZoneValue = () => {
    // Assuming studentDetails is your data
    console.log(props.zone.mqtt_topic)
    let tempValue = formatted;
    console.log(tempValue)
    const mqtt_topic_value = props.zone.mqtt_topic.split('/');
    console.log(mqtt_topic_value);
    mqtt_topic_value.pop();


    for (const mqtt of mqtt_topic_value) {
      if (tempValue && tempValue.hasOwnProperty(mqtt)) {
        tempValue = tempValue[mqtt];
      } else {
        tempValue = null;
        break;
      }
    }
    console.log(tempValue)
    if(tempValue === 0) {
    setChecked(false);
    setToggleZoneLoader(false)
    } else {
      setChecked(true)
      setToggleZoneLoader(false)
    }
  }

  const publishZoneMessage = (val, mqtt_topic) => {
    console.log(val)
    console.log(mqtt_topic)
    if (val === true) {
      Swal.fire({
        title: 'Are you sure? You want turn on zone',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (mqttClient && mqttClient.isConnected()) {
            setToggleZoneLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(val, mqtt_topic)
            mqttClient.publish(mqtt_topic, "1", 0, false, (error) => {
              console.log("Publish Connect---------", error)
              if (error) {
                console.error('Error publishing to topic:', error);
                setToggleZoneLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                setToggleZoneLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure? You want turn off zone',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log(mqttClient.isConnected())
          if (mqttClient && mqttClient.isConnected()) {
            setToggleZoneLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(mqttClient)
            mqttClient.publish(mqtt_topic, "0", 0, false, (error) => {
              console.log("ABC")
              console.log("Publish Connect---------")
              if (error) {
                console.error('Error publishing to topic:', error);
                setToggleZoneLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                setToggleZoneLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    }
  };

  useEffect(() => {
    updateZoneValue()
  },[formatted])

  useEffect(() => {
    apiZoneFeedback()
  },[])

  return (
    <>
     {checked !== null && 
      <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className={`d-flex justify-content-between align-items-start mt-3  ${checked === false ? "field_off" : "field_on"}`}>
            <a href="javascript:void(0)" className="ms-4 d-flex align-items-center">
              <div className="my-1">
                <h5 className="title">{props.zone.name}</h5>
              </div>
            </a>
            {privileges.includes("zone_toggle") && <div className='mt-1'>
              {/* {toggleZoneLoader === true ? <div className='text-white'>Please Wait..</div> : */}
              <ReactSwitch
                // disabled={buttonDisabled}
                checked={checked}
                onChange={(event) => publishZoneMessage(event, props.zone.mqtt_topic)}
              />
            </div>}
            {privileges.includes("zone_ellipsis") && <div className="dropdown me-3 my-1">
              <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
              <div className="dropdown-menu dropdown-menu-end">
                <ul className="link-list-opt no-bdr">
                {privileges.includes("edit_zone") && <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editZone" onClick={() => props.editZone(props.zone.id)}><em className="icon ni ni-edit"></em><span>Edit Zone</span></a></li>}
                
                {privileges.includes("delete_zone") && <li><a href='javascript:void(0)' onClick={() => deleteZoneAlert(props.zone)}><em className="icon ni ni-delete"></em><span>Delete Zone</span></a></li>}
                </ul>
              </div>
            </div>}
          </div>
          <div className="card-inner">
            {privileges.includes("zone_setting") && <div className="team-view-button">
              <a href="javascript:void(0)" className="btn btn-outline-light w-100px" onClick={() => onChangeSidebar("PlantZoneSchedular", props.zone)}><span>Setting</span></a>
              {/* <div className=''>
                <span className="sub-text-sync">Last Update on : <br />{updatedTime}</span>
              </div> */}
            </div>}
            <div class="invest-data mt-2">
              <div class="invest-data-amount g-2">
                <div class="invest-data-history">
                  <div class="title">Current Status</div>
                  <div class="amount">{props.zone.name} </div>
                  {lastZoneFeedbackTime ? <div class="amount">{lastZoneFeedbackTime} </div> : <p>Loading...</p>}
                </div>
              </div>
            </div>
            {/* <div className='mt-2'>
              <span className="sub-text-mysync">Last <span className='fw-bold'>{lastZoneFeedbackName} {lastZoneFeedbackTime}</span></span>
            </div> */}
            {/* <div className='d-flex justify-content-between'>
              <span className="sub-text-sync">Refresh the button</span>
              <a href="javascript:void(0)" className="logo-link" onClick={() => syncRefresh(props.zone.id, props.zone.mqtt_topic)}>
                <img className="img-fluid" src="./images/sync.png" srcSet="./images/sync.png" alt="sync" />
              </a>
            </div> */}
          </div>
        </div>
      </div>}

      <DeleteModalZone message="Warning: Deleting this Zone will effects the below following entities:"
      deleteZoneResponse={deleteZoneResponse} 
      deleteEntity={deleteZone}
      userId={userId} />
      <button
        id="zonemodalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDeleteZone"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModalZone"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDeleteZone"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>
    </>
  )
}

export default ZoneRender