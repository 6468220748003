import React from 'react'
import PumpDetails from './PumpDetails'
import ZoneDetails from './ZoneDetails'

const PumpZoneTankDetails = (props) => {

  return (
    <>
      <PumpDetails 
      handleSidebar={props.handleSidebar} 
      setSchedularZone={props.setSchedularZone} />

      <ZoneDetails 
      handleSidebar={props.handleSidebar} 
      setSchedularZone={props.setSchedularZone} />
    </>
  )
}

export default PumpZoneTankDetails