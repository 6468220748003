import React from 'react'

const DeleteModalTank = (props) => {
    console.log(props.deleteResponse)
  return (
    <>
      <div className="modal fade" tabindex="-1" id="assignedDeleteTank">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            {/* <a href="#" className="close" data-bs-dismiss="modal" aria-label="Close">
              <em className="icon ni ni-cross"></em>
            </a> */}
            <div className="modal-header">
              <h5 className="modal-title">{props.message}</h5>
            </div>
            {props.deleteResponse && <div className="modal-body">
            <div className='card'>
                <div className='card-body'>
                {props.deleteResponse.admin.length !== 0 && <div className='admin'>
                <h6 className='mb-0'>Admin : </h6>
                  {props.deleteResponse.admin.map((api) => {
                    return <p className='fs-5 pt-0'> {api.details.user.username} </p>
                  })}
              </div>}
              {props.deleteResponse.plantowner.length !== 0 && <div className='plantowner mt-2'>
                <h6 className='mb-0'>Plant Owner : </h6>
                  {props.deleteResponse.plantowner.map((api) => {
                    return <p className='fs-5 pt-0'> {api.details.user.username} </p>
                  })}
              </div>}
              {props.deleteResponse.plant.length !== 0 && <div className='plant mt-2'>
                <h6 className='mb-0'>Plant : </h6>
                <ul>
                  {props.deleteResponse.plant.map((api) => {
                    return <p className='fs-5 pt-0'> {api.details.name} </p>
                  })}
                </ul>
              </div>}
              {props.deleteResponse.pump.length !== 0 && <div className='pump mt-2'>
                <h6 className='mb-0'>Pump : </h6>
                  {props.deleteResponse.pump.map((api) => {
                    return <><p className='fs-5 pt-0 mb-0'> {api.details.name} </p>
                    {api.scheduler.map((sch) => {
                      const schedule_day = sch.scheduler_day.slice(1, -1)
                      const schedule_Week = schedule_day.split(', ')
                      var arr = []
                      for (var i = 0; i <= schedule_Week.length - 1; i++) {
                        const schedule = schedule_Week[i].substring(1, schedule_Week[i].length - 1);
                        arr.push(schedule)
                      }
                      return <p className='fs-5 pt-0 mb-0'>Scheduler Day : {arr.length - 1 > 0 ? arr.join(", ") : arr} and Scheduler Time : {sch.scheduler_time} </p>
                    })}</>
                  })}
              </div>}
              {props.deleteResponse.zone.length !== 0 && <div className='zone mt-2'>
                <h6 className='mb-0'>Zone</h6>
                  {props.deleteResponse.zone.map((api) => {
                    return <><p className='fs-5 pt-0 mb-0'> {api.details.name} </p>
                    {api.scheduler.map((sch) => {
                      const schedule_day = sch.scheduler_day.slice(1, -1)
                      const schedule_Week = schedule_day.split(', ')
                      var arr = []
                      for (var i = 0; i <= schedule_Week.length - 1; i++) {
                        const schedule = schedule_Week[i].substring(1, schedule_Week[i].length - 1);
                        arr.push(schedule)
                      }
                      return <p className='fs-5 pt-0 mb-0'>Scheduler Day : {arr.length - 1 > 0 ? arr.join(", ") : arr} and Scheduler Time {sch.scheduler_time} </p>
                    })}</>
                  })}
              </div>}
              {props.deleteResponse.tank.length !== 0 && <div className='tank mt-2'>
                <h6 className='mb-0'>Tank</h6>
                  {props.deleteResponse.tank.map((api) => {
                    return <><p className='fs-5 pt-0 mb-0'> {api.details.name} </p>
                    {api.scheduler.map((sch) => {
                      const schedule_day = sch.scheduler_day.slice(1, -1)
                      const schedule_Week = schedule_day.split(', ')
                      var arr = []
                      for (var i = 0; i <= schedule_Week.length - 1; i++) {
                        const schedule = schedule_Week[i].substring(1, schedule_Week[i].length - 1);
                        arr.push(schedule)
                      }
                      return <p className='fs-5 pt-0 mb-0'>Scheduler Day : {arr.length - 1 > 0 ? arr.join(", ") : arr} and Scheduler Time {sch.scheduler_time} </p>
                    })}</>
                  })}
              </div>}
                </div>
              </div>
            </div>}
            <div className="modal-footer">
              <button type="button" className='btn btn-primary' onClick={() => props.deleteEntity()}> Ok </button>
              <button type="button" className='btn btn-primary' data-bs-dismiss="modal" aria-label="Close"> Cancel </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteModalTank