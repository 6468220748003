// TODO : privilege
import axios from 'axios';
import React, { useContext } from 'react'
import { VARIABLES } from '../../Constant';
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";
import swal from 'sweetalert';
import { StateContext } from '../../Context/StateContext';

const TankSchedulerTable = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const { tankHandleDetails, setTankHandleDetails } = useContext(StateContext)

  const updateHandler = () => {
    const url = VARIABLES.url + '/api/mqtt-json?node_id=' + tankHandleDetails.node_id
    console.log(url)
    var config = {
      method: 'get',
      url: url,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        swal({
          icon: "success",
          title: "Scheduler Updated Successfully",
          showConfirmButton: false,
          timer: 2000
        })
      })
      .catch(function (error) {
        console.log(error);
        swal({
          icon: 'error',
          title: 'Failed to update Scheduler',
          showConfirmButton: false,
          timer: 2000
        })
      });
  }

  const deleteProject = (id) => {
    const url = VARIABLES.url + `/api/scheduler-details/${id}`
    console.log(url)
    var config = {
      method: 'delete',
      url: url,
      headers: {}
    };
    console.log("deleted project reached");
    axios(config).then((response) => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Deleted Successfully!',
        showConfirmButton: true
      })
      console.log(JSON.stringify(response.data));
      // window.location.reload();
      props.setSchedularData(props.schedulerData.filter((user) => {
        return user.id !== id
      }));
    })
      .catch((error) => {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Selected Pump Scheduler not deleted.',
          showConfirmButton: true
        })
        console.log(error);
      });
  };

  const deleteProjectAlert = (id) => {
    Swal.fire({
      title: 'Are you sure? You will not be able to recover this project!',
      showDenyButton: true,
      confirmButtonText: 'Yes, delete it!',
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProject(id);
      }
    })

  };

  return (
    <>
      <div className='row'>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card card-full">
            <div class="card-inner">
              <div class="card-title-group">
                <div class="card-title">
                  <h6 class="title">Tank Scheduler</h6>
                </div>
                {/* TODO : privilege */}
                {privileges.includes("update_pump_scheduler") && <div>
                  <button className='btn btn-primary btn-dim' onClick={updateHandler}>Update</button>
                </div>}
              </div>
            </div>
            {props.isLoading ? <div className='card-inner'> Loading...</div> :
              <div className='table-responsive'>
                <table className='table'>
                  <div class="nk-tb-list mt-2">
                    <div class="nk-tb-item nk-tb-head">
                      <div class="nk-tb-col"><span>Entity Name</span></div>
                      <div class="nk-tb-col"><span>Scheduler Days</span></div>
                      <div class="nk-tb-col"><span>Scheduler Time</span></div>
                      <div class="nk-tb-col"><span>Scheduler ON/OFF status</span></div>
                      <div class="nk-tb-col"><span>Edit</span></div>
                      <div class="nk-tb-col"><span>Delete</span></div>
                    </div>
                    {props.schedulerData.length === 0 &&
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    }
                    {
                      props.schedulerData.length > 0 &&
                      <tbody>
                        {props.schedulerData.map((set, index) => {
                          const schedule_day = set.scheduler_day.slice(1, -1)
                          const schedule_Week = schedule_day.split(', ')
                          var arr = []
                          for (var i = 0; i <= schedule_Week.length - 1; i++) {
                            const schedule = schedule_Week[i].substring(1, schedule_Week[i].length - 1);
                            arr.push(schedule)
                          }
                          return <div class="nk-tb-item">
                            <div class="nk-tb-col">
                              <span class="tb-lead"><a href="#">{set.entity.name}</a></span>
                            </div>
                            <div class="nk-tb-col">
                              <div class="user-card">
                                <div class="user-name">
                                  <span class="tb-lead">{arr.length - 1 > 0 ? arr.join(", ") : arr}</span>
                                </div>
                              </div>
                            </div>
                            <div class="nk-tb-col">
                              <span class="tb-sub">{set.scheduler_time}</span>
                            </div>
                            <div class="nk-tb-col">
                              {set.is_on === true ? <span class="badge badge-dot badge-dot-xs bg-success">ON</span>
                                : <span class="badge badge-dot badge-dot-xs bg-danger">OFF</span>}
                            </div>
                            {privileges.includes("edit_pump_scheduler") && <div class="nk-tb-col">
                              <button className='ni ni-edit btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#editSchedule" onClick={() => props.editSchedule(set.id)}></button>
                            </div>}

                            {privileges.includes("delete_pump_scheduler") && <div class="nk-tb-col">
                              <button className='ni ni-trash btn btn-outline-primary' onClick={() => deleteProjectAlert(set.id)}></button>
                            </div>}
                          </div>
                        })}
                      </tbody>
                    }
                  </div>
                </table>
              </div>}
          </div>
          {/* <!-- .card --> */}
        </div>
      </div>
    </>
  )
}

export default TankSchedulerTable