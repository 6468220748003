import React, { useContext, useEffect, useState } from 'react'
import ParticularTankCardFeedback from './ParticularTankCardFeedback';
import { VARIABLES } from '../../Constant';
import axios from 'axios'
import  secureLocalStorage  from  "react-secure-storage";
import { StateContext } from '../../Context/StateContext';

const TankCards = (props) => {
  const { tankHandleDetails, setTankHandleDetails } = useContext(StateContext)
  const privileges = secureLocalStorage.getItem('privileges')
  const [particulartankLogs,setParticularTankLogs] = useState([])

  const onChangeSidebar = (section) => {
    props.handleSidebar(section)
  }

  // API called for particular data of Tank Logs and stored in "particulartankLogs"
  const apiParticularTankLogsData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + '/api/current-entity-status-log?entity_id=' + tankHandleDetails.id);
      setParticularTankLogs(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiParticularTankLogsData();
  }, [])

  return (
    <>
      {privileges.includes("back_pump") && <div className='nk-block-head' style={{paddingBottom:"1.25rem"}}>
        <div class="nk-block-head-content">
          <a href="javascript:void(0)" class="btn btn-outline-light bg-white d-none d-sm-inline-flex" onClick={()=> onChangeSidebar("pump_details")}><em
            class="icon ni ni-arrow-left"></em><span>Back</span></a>
          <a href="javascript:void(0)"
            class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none" onClick={()=> onChangeSidebar("pump_details")}><em
              class="icon ni ni-arrow-left"></em></a>
        </div>
      </div>}

    <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title page-title">{tankHandleDetails.name}</h4>
          </div>
          {/* <!-- .nk-block-head-content --> */}
        </div>
      </div>

      <ParticularTankCardFeedback particulartankLogs={particulartankLogs} />
    </>
  )
}

export default TankCards