import React, { useContext, useState } from 'react'
import UserDetails from '../UserDetails/UserDetails'
import PlantDetails from '../PlantDetails/PlantDetails'
import PlantZones from '../PlantDetails/PlantZones'
import PlantZoneSchedular from '../PlantDetails/PlantZoneSchedular'
import Dashboard from '../Dashboard/Dashboard'
import TankStatus from '../TankStatus/TankStatus'
import TankStatusFeedback from '../TankCards/TankStatusFeedback'
import AllAdminStatus from '../AllAdminStatus/AllAdminStatus'
import AllPlantOwner from '../AllPlantOwner/AllPlantOwner'
import TankCards from '../TankCards/TankCards'
import ZoneFeedback from '../ZoneFeedback/ZoneFeedback'
import ParticularZoneFeedback from '../ZoneFeedback/ParticularZoneFeedback'
import PumpZoneTankDetails from '../PumpZoneTankDetails/PumpZoneTankDetails'
import PumpScheduler from '../PumpSchedulerSet/PumpScheduler'
import ParticularPumpFeedback from '../PumpFeedback/ParticularPumpFeedback'
import PumpFeedback from '../PumpFeedback/PumpFeedback'
import TankScheduler from '../TankSchedulerSet/TankScheduler'
import { StateContext } from '../../Context/StateContext'

const Content = (props) => {
  const [schedularZone,setSchedularZone] = useState([])
  console.log(schedularZone);

  // const { tankHandleDetails, setTankHandleDetails } = useContext(StateContext)
  
  return (
    <>
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* <div className='nk-block'> */}
                {props.sidebar === "dashboard" && <Dashboard handleSidebar={props.handleSidebar} />}
                {props.sidebar === "user_management" && <UserDetails />}
                {props.sidebar === "pump_details" && <PumpZoneTankDetails
                setSchedularZone={setSchedularZone} 
                handleSidebar={props.handleSidebar}  />}
                {props.sidebar === "Admin Overview" && <AllAdminStatus handleSidebar={props.handleSidebar} />}
                {props.sidebar === "Plant Overview" && <AllPlantOwner handleSidebar={props.handleSidebar} />}
                {props.sidebar === "PlantZoneSchedular" && <PlantZoneSchedular handleSidebar={props.handleSidebar} setSchedularZone={setSchedularZone} schedularZone={schedularZone} />}
                {props.sidebar === "PumpSchedular" && <PumpScheduler handleSidebar={props.handleSidebar} setSchedularZone={setSchedularZone} schedularZone={schedularZone} />}
                {props.sidebar === "TankCardHandler" && <TankCards handleSidebar={props.handleSidebar} />}
                {props.sidebar === "zone feedback" && <ZoneFeedback handleSidebar={props.handleSidebar} />}
                {props.sidebar === "pump feedback" && <PumpFeedback handleSidebar={props.handleSidebar} />}
                {props.sidebar === "particular zone feedback" && <ParticularZoneFeedback handleSidebar={props.handleSidebar} schedularZone={schedularZone} />}
                {props.sidebar === "particular pump feedback" && <ParticularPumpFeedback handleSidebar={props.handleSidebar} schedularZone={schedularZone} />}
                {/* {props.sidebar === "Water Tank Management" && <TankStatus handleSidebar={props.handleSidebar} setTankHandleDetails={setTankHandleDetails} />} */}
                {props.sidebar === "Tank Status" && <TankStatusFeedback handleSidebar={props.handleSidebar} />}
                {props.sidebar === "TankSchedular" && <TankScheduler handleSidebar={props.handleSidebar} />}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content