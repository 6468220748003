import React from 'react'
import { useState, useEffect, useContext } from 'react';
import ReactSwitch from 'react-switch';
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from '../../Constant';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import DeleteModalTank from '../Modals/DeleteModalTank';
import { StateContext } from '../../Context/StateContext';
import { useGlobalContext } from '../Context';

const TankRender = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  const [deleteTankResponse, setDeleteTankResponse] = useState(null)
  console.log(deleteTankResponse)
  const [userId, setUserId] = useState(null)

  const [tankUpdate, setTankUpdate] = useState(null)

  const [tankChecked, setTankChecked] = useState(null)
  console.log(tankChecked)

  const { setTankHandleDetails } = useContext(StateContext)

  const { setFormatted, setPumpChecked, formatted, pumpChecked, mqttClient, setIsConnected } = useGlobalContext();

  const [lastTankFeedbackTime, setLastTankFeebbackTime] = useState()
  console.log(lastTankFeedbackTime)

  const onChangeSidebar = (section, tank) => {
    props.handleSidebar(section)
    setTankHandleDetails(tank)
  }

  const apiTankFeedback = async () => {
    try {
      const url = VARIABLES.url + '/api/current-entity-status?entity_id=' + props.tank.id
      console.log(url)
      const res = await axios.get(url);
      console.log(res.data)
      if(res.data.length !== 0) {
        const api_data = res.data?.[0]?.entity.mqtt_topic
        const feedback_value = parseInt(res.data?.[0]?.entity_status);
        setLastTankFeebbackTime(moment(res.data?.[0]?.config_last_updated).format('MMMM Do YYYY, hh:mm a'));
        updatedTankAPI(api_data, feedback_value)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatedTankAPI = (api_data, feedback_value) => {
    console.log("HII")
    const split_entity_data = api_data.split('/');

    setFormatted((prevData) => {
      let newData = { ...prevData };

      let temp = newData;

      for (let i = 0; i < split_entity_data.length - 2; i++) {
        const mqtt_data = split_entity_data[i];
        temp[mqtt_data] = temp[mqtt_data] || {};
        temp = temp[mqtt_data];
      }

      const pin = split_entity_data[split_entity_data.length - 2];
      temp[pin] = temp[pin] || 0;
      temp[pin] = feedback_value;

      return { ...newData };
    });
  }

  // Delete Function : delete the specific id 
  const deleteTank = () => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${userId.id}&type=entity`,
      headers: {}
    };
    console.log("deleted project reached");
    axios(config).then((response) => {
      Swal.fire({
        text: 'Tank Deleted Successfully!',
        icon: 'success'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModalTank').click();
          props.getTankDetailsforPlantOWner()
          updateHandler()
        }
      })
      console.log(JSON.stringify(response.data));
      console.log(props.tankDetails)
    })
      .catch((error) => {
        Swal.fire({
          text: 'Failed to Delete Tank',
          icon: 'warning'
        })
        console.log(error);
      });
  };

  const updateHandler = () => {
    const url = VARIABLES.url + '/api/mqtt-json?node_id=' + userId.node_id
    console.log(url)
    var config = {
      method: 'get',
      url: url,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          icon: "success",
          title: "Scheduler Updated Successfully",
          showConfirmButton: false,
          timer: 2000
        })
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update Scheduler',
          showConfirmButton: false,
          timer: 2000
        })
      });
  }

  const deleteTankAlert = async (data) => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/delete-confirmation?id=" + data.id + "&type=entity");
      document.getElementById('tankmodalDelete').click();
      console.log(res.data)
      setDeleteTankResponse(res.data)
      setUserId(data)
    } catch (error) {
      console.log(error);
    }

  };

  const updateTankValue = () => {
    console.log(props.tank)
    // Assuming studentDetails is your data
    let tempValue = formatted;
    console.log(tempValue)
    const mqtt_topic_value = props.tank?.mqtt_topic?.split('/');
    console.log(mqtt_topic_value);
    mqtt_topic_value?.pop();

    for (const mqtt of mqtt_topic_value) {
      if (tempValue && tempValue.hasOwnProperty(mqtt)) {
        tempValue = tempValue[mqtt];
      } else {
        tempValue = null;
        break;
      }
    }
    console.log(tempValue)
    if (tempValue === 0) {
      setTankChecked(false);
      // setTogglePumpLoader(false)
    } else if (tempValue === null) {
      setTankChecked(false)
      // setTogglePumpLoader(false)
    } else {
      setTankChecked(true)
    }
  }

  const publishTankMessage = (val, mqtt_topic) => {
    console.log(val)
    console.log(mqtt_topic)
    if (val === true) {
      Swal.fire({
        title: 'Are you sure? You want turn on Tank',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (mqttClient && mqttClient.isConnected()) {
            // setTogglePumpLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(val, mqtt_topic)
            mqttClient.publish(mqtt_topic, "1", 0, false, (error) => {
              console.log("Publish Connect---------", error)
              if (error) {
                console.error('Error publishing to topic:', error);
                // setTogglePumpLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                // setTogglePumpLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure? You want turn off Tank',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log(mqttClient.isConnected())
          if (mqttClient && mqttClient.isConnected()) {
            // setTogglePumpLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(mqttClient)
            mqttClient.publish(mqtt_topic, "0", 0, false, (error) => {
              console.log("ABC")
              console.log("Publish Connect---------")
              if (error) {
                console.error('Error publishing to topic:', error);
                // setTogglePumpLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                // setTogglePumpLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    }
  };

  useEffect(() => {
    updateTankValue()
  }, [formatted])

  useEffect(() => {
    apiTankFeedback();
  }, [])

  return (
    <>

      {tankChecked !== null &&
        <div className="col-lg-8 col-md-10 col-sm-12" style={{ marginTop: "20px" }}>
          <div className="card h-100">
            <div className={`d-flex justify-content-between align-items-start mt-3 ${tankChecked === null ? "field_off" : tankChecked === false ? "field_off" : "field_on"}`}>
              <a href="javascript:void(0)" className="ms-4 d-flex align-items-center">
                <div className="my-1">
                  <h5 className="title">{props.tank.name}</h5>
                </div>
              </a>

              {/* TODO : privilege */}
              {privileges.includes("pump_toggle") && <div className='mt-1'>
                <ReactSwitch
                  checked={tankChecked}
                  onChange={(event) => publishTankMessage(event, props.tank.mqtt_topic)}
                />
                {/* } */}
              </div>}
              {privileges.includes("tank_ellipsis") && <div className="dropdown me-3 my-1">
                <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <ul className="link-list-opt no-bdr">
                    {privileges.includes("edit_tank") && <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editTank" onClick={() => props.editTankDetails(props.tank.id)}><em className="icon ni ni-edit"></em><span>Edit Tank</span></a></li>}

                    {privileges.includes("delete_tank") && <li><a href='javascript:void(0)' onClick={() => deleteTankAlert(props.tank)}><em className="icon ni ni-delete"></em><span>Delete Tank</span></a></li>}
                  </ul>
                </div>
              </div>}
            </div>
            <div className="card-inner">
              {privileges.includes("tank_setting") && <div className="team-view-button">
                <a href="javascript:void(0)" className="btn btn-outline-light w-100px" onClick={() => onChangeSidebar("TankSchedular", props.tank)}><span>Setting</span></a>
                {/* <div className=''>
                <span className="sub-text-mysync">Last <span className='fw-bold'>{lastTankFeedbackName} <br />{lastTankFeedbackTime}</span></span>
              </div> */}
              </div>}
              <div class="invest-data mt-2">
                <div class="invest-data-amount g-2">
                  <div class="invest-data-history">
                    <div class="title">Current Status</div>
                    {props.tank ? <div class="amount">{props.tank.name} </div> : <p>NA</p>}
                    {lastTankFeedbackTime ? <div class="amount">{lastTankFeedbackTime} </div> : <p>No Update</p>}
                  </div>
                </div>
              </div>
              {/* <div className='mt-2'>
              <span className="sub-text-sync">Last Update on : {updatedTime}</span>
            </div> */}
              {/* <div className='d-flex justify-content-between'>
              <span className="sub-text-sync">Refresh the button</span>
              <a href="javascript:void(0)" className="logo-link" onClick={() => syncRefresh(props.tank.id)}>
                <img className="img-fluid" src="./images/sync.png" srcSet="./images/sync.png" alt="sync" />
              </a>
            </div> */}
            </div>
          </div>
        </div>}

      <DeleteModalTank message="Warning: Deleting this Tank will effects the below following entities:"
        deleteResponse={deleteTankResponse}
        deleteEntity={deleteTank}
        userId={userId} />
      <button
        id="tankmodalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDeleteTank"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModalTank"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDeleteTank"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>

    </>
  )
}

export default TankRender