import React from 'react'
import  secureLocalStorage  from  "react-secure-storage";
import { useGlobalContext } from '../Context';
import { v4 as uuidv4 } from 'uuid';

const Header = (props) => {
  const username = secureLocalStorage.getItem("username")
  const email = secureLocalStorage.getItem("email")
  const role_name = secureLocalStorage.getItem("role_name")
  const { isConnected, getMqttConnection } = useGlobalContext();
  return (
    <>
      <div className="nk-header nk-header-fixed is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <a href="javascript:void(0)" onClick={props.showSidebarMobile} className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
            </div>
            <div className="nk-header-brand d-xl-none">
              <a href="javascript:void(0)" className="logo-link">
                <img className="logo-light logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="logo" />
                <img className="logo-dark logo-img" src="./images/zoom-solar-logo.png" srcSet="./images/zoom-solar-logo.png 2x" alt="logo-dark" />
              </a>
            </div>
            {/* <!-- .nk-header-brand --> */}
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                <li>
                <div className="nk-block-head-content">
                  <div className="page-title connection">
                    {/* <p>{`REACT-${uuidv4()}-MQTT`}</p> */}
                    <div className={isConnected ? "connected me-2" : "disconnected me-2"}></div>
                    <div className='fs-6'>{isConnected ? "Connected" : "Disconnected"}</div>
                  <button className="btn ms-3 btn-sm btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="right" title="Status disconnect then refresh the button to connect mqtt." onClick={() => getMqttConnection()} ><em class="icon ni ni-reload"></em></button>
                  </div>
                </div>
                </li>
                <li className="dropdown user-dropdown">
                  <a href="javascript:void(0)" className="dropdown-toggle me-n1" data-bs-toggle="dropdown">
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                      <div className="user-info d-none d-xl-block">
                        <div className="user-status user-status-unverified"> {role_name} </div>
                        <div className="user-name dropdown-indicator">{username}</div>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div className="user-card">
                        <div className="user-avatar">
                          <span>VU</span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">{username}</span>
                          <span className="sub-text">{email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li><a href="javascript:void(0)"><em className="icon ni ni-user"></em><span>Role : {role_name}</span></a></li>
                        <li><a href="javascript:void(0)" onClick={props.logoutHandler}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- .nk-header-wrap --> */}
        </div>
        {/* <!-- .container-fliud --> */}
      </div>
    </>
  )
}

export default Header