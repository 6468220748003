import React from 'react'
import { useState, useContext, useEffect } from 'react';
import ReactSwitch from 'react-switch';
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from '../../Constant';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import DeleteModal from '../Modals/DeleteModal';
import { useGlobalContext } from '../Context';
import { PumpDetailsContext } from '../../Context/PumpDetailsContext';

const PumpRender = (props) => {
  const privileges = secureLocalStorage.getItem('privileges')
  
  const {setSidebarPumps,sidebarPumps,pumpDetails,apiPumpDetails,apiPumpPlantOwnerDetails} = useContext(PumpDetailsContext);
  const [deleteResponse, setDeleteResponse] = useState(null)
  const [userId, setUserId] = useState(null)

  const { setFormatted, setPumpChecked, formatted, pumpChecked, mqttClient, setIsConnected } = useGlobalContext();

  const [togglePumpLoader, setTogglePumpLoader] = useState(false)
  // console.log(togglePumpLoader)

  const [lastPumpFeedbackTime, setLastPumpFeebbackTime] = useState()
  console.log(lastPumpFeedbackTime)

  const handleChange = (val) => {
    if (val === true) {
      console.log(val)
      Swal.fire({
        title: 'Are you sure? You want turn on pump',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // setPumpChecked(val)
          // setTogglePumpLoader(true)
          const url = VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${props.pump.mqtt_topic}&is_on=${val}`
          console.log(url)
          var config = {
            method: 'get',
            // url: VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${VARIABLES.subdomain}/${VARIABLES.project_id}/${VARIABLES.node_id}/P_D4/values&is_on=${val}`,
            url: url,
            headers: {}
          };

          axios(config)
            .then((response) => {
              const response_message = response.data
              console.log(JSON.stringify(response.data));
              setTogglePumpLoader(true)
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
    }
    if (val === false) {
      Swal.fire({
        title: 'Are you sure? You want turn off pump',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // setPumpChecked(val)
          // setTogglePumpLoader(true)
          const url = VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${props.pump.mqtt_topic}&is_on=${val}`
          console.log(url)
          var config = {
            method: 'get',
            // url: VARIABLES.url + `/api/mqtt-trigger?mqtt_topic=${VARIABLES.subdomain}/${VARIABLES.project_id}/${VARIABLES.node_id}/P_D4/values&is_on=${val}`,
            url: url,
            headers: {}
          };

          axios(config)
            .then((response) => {
              const response_message = response.data
              console.log(JSON.stringify(response.data));
              setTogglePumpLoader(true)
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
    }
  }

  const onChangeSidebar = (section, pump) => {
    props.handleSidebar(section)
    props.setSchedularZone(pump)
  }

  const apiPumpFeedback = async () => {
    try {
      const url = VARIABLES.url + '/api/current-entity-status?entity_id=' + pumpDetails
      console.log(url)
      const res = await axios.get(url);
      console.log(res.data)
      const api_data = res.data?.[0]?.entity.mqtt_topic
      const feedback_value = parseInt(res.data?.[0]?.entity_status);
      setLastPumpFeebbackTime(moment(res.data?.[0]?.config_last_updated).format('MMMM Do YYYY, hh:mm a'));
      updatedPumpAPI(api_data, feedback_value)
    } catch (error) {
      console.log(error);
    }
  };

  const updatedPumpAPI = (api_data, feedback_value) => {
    const split_entity_data = api_data.split('/');

    setFormatted((prevData) => {
      let newData = { ...prevData };

      let temp = newData;

      for (let i = 0; i < split_entity_data.length - 2; i++) {
        const mqtt_data = split_entity_data[i];
        temp[mqtt_data] = temp[mqtt_data] || {};
        temp = temp[mqtt_data];
      }

      const pin = split_entity_data[split_entity_data.length - 2];
      temp[pin] = temp[pin] || 0;
      temp[pin] = feedback_value;

      return { ...newData };
    });
  }

  const deletePump = () => {
    var config = {
      method: 'delete',
      url: VARIABLES.url + `/api/delete?id=${userId.id}&type=entity`,
      headers: {}
    };
    axios(config).then((response) => {
      Swal.fire({
        text: 'Pump Deleted Successfully!',
        icon: 'success'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log("yes")
          document.getElementById('closeModalPump').click();
          props.setPumps(props.pumps.filter((user) => {
            return user.id !== userId?.id
          }));
          setSidebarPumps(sidebarPumps.filter((user) => {
            return user.id !== userId?.id
          }));
          updateHandler()
          // apiPumpDetails()
          // apiPumpPlantOwnerDetails()
        }
      })
      console.log(JSON.stringify(response.data));
    })
      .catch((error) => {
        Swal.fire({
          text: 'Failed to Delete Pump',
          icon: 'warning'
        })
        console.log(error);
      });
  };

  const deletePumpAlert = async (data) => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/delete-confirmation?id=" + data.id + "&type=entity");
      document.getElementById('modalDelete').click();
      console.log(res.data)
      setDeleteResponse(res.data)
      setUserId(data)
    } catch (error) {
      console.log(error);
    }
  };

  const updateHandler = () => {
    const url = VARIABLES.url + '/api/mqtt-json?node_id=' + userId?.node_id
    console.log(url)
    var config = {
      method: 'get',
      url: url,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          icon: "success",
          title: "Scheduler Updated Successfully",
          showConfirmButton: false,
          timer: 2000
        })
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update Scheduler',
          showConfirmButton: false,
          timer: 2000
        })
      });
  }

  const updatePumpValue = () => {
    // Assuming studentDetails is your data
    let tempValue = formatted;
    console.log(tempValue)
    const mqtt_topic_value = props.pump.mqtt_topic.split('/');
    console.log(mqtt_topic_value);
    mqtt_topic_value.pop();

    for (const mqtt of mqtt_topic_value) {
      if (tempValue && tempValue.hasOwnProperty(mqtt)) {
        tempValue = tempValue[mqtt];
      } else {
        tempValue = null;
        break;
      }
    }
    console.log(tempValue)
    if (tempValue === 0) {
      setPumpChecked(false);
      setTogglePumpLoader(false)
    } else {
      setPumpChecked(true)
      setTogglePumpLoader(false)
    }
  }

  const publishPumpMessage = (val, mqtt_topic) => {
    console.log(val)
    console.log(mqtt_topic)
    if (val === true) {
      Swal.fire({
        title: 'Are you sure? You want turn on Pump',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          if (mqttClient && mqttClient.isConnected()) {
            setTogglePumpLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(val, mqtt_topic)
            mqttClient.publish(mqtt_topic, "1", 0, false, (error) => {
              console.log("Publish Connect---------", error)
              if (error) {
                console.error('Error publishing to topic:', error);
                setTogglePumpLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                setTogglePumpLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure? You want turn off Pump',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          console.log(mqttClient.isConnected())
          if (mqttClient && mqttClient.isConnected()) {
            setTogglePumpLoader(true)
            // Publish a message to the MQTT topic
            setIsConnected(true);
            console.log("Connect---------")
            console.log(mqttClient)
            mqttClient.publish(mqtt_topic, "0", 0, false, (error) => {
              console.log("ABC")
              console.log("Publish Connect---------")
              if (error) {
                console.error('Error publishing to topic:', error);
                setTogglePumpLoader(false)
              } else {
                console.log(`Published to ${mqtt_topic}: ${"1"}`);
                setTogglePumpLoader(false)
              }
            });
          } else {
            console.log("Connection lost")
            setIsConnected(false);
          }
        }
      })
    }
  };

  useEffect(() => {
    updatePumpValue()
  }, [formatted])

  useEffect(() => {
    apiPumpFeedback();
  }, [])

  return (
    <>
      {pumpChecked !== null &&
        <div className="col-lg-8 col-md-10 col-sm-12" style={{ marginTop: "20px" }}>
          <div className="card h-100">
            <div className={`d-flex justify-content-between align-items-start mt-3 ${pumpChecked === false ? "field_off" : "field_on"}`}>
              <a href="javascript:void(0)" className="ms-4 d-flex align-items-center">
                <div className="my-1">
                  <h5 className="title">{props.pump.name}</h5>
                </div>
              </a>
              {privileges.includes("pump_toggle") && <div className='mt-1'>
                {/* {togglePumpLoader === true ? <div className='text-white'>Please Wait..</div> : */}
                <ReactSwitch
                  // disabled={buttonDisabled}
                  checked={pumpChecked}
                  onChange={(event) => publishPumpMessage(event, props.pump.mqtt_topic)}
                />
                {/* } */}
              </div>}
              {privileges.includes("pump_ellipsis") && <div className="dropdown me-3 my-1">
                <a href="#" className="dropdown-toggle btn btn-sm btn-icon btn-trigger me-n1" data-bs-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                <div className="dropdown-menu dropdown-menu-end">
                  <ul className="link-list-opt no-bdr">
                    {privileges.includes("edit_pump") && <li><a href='javascript:void(0)' data-bs-toggle="modal" data-bs-target="#editPump" 
                    onClick={() => props.setEditedPump(props.pump)}><em className="icon ni ni-edit"></em><span>Edit Pump</span></a></li>}

                    {privileges.includes("delete_pump") && <li><a href='javascript:void(0)' onClick={() => deletePumpAlert(props.pump)}><em className="icon ni ni-delete"></em><span>Delete Pump</span></a></li>}
                  </ul>
                </div>
              </div>}
            </div>
            <div className="card-inner">
              {privileges.includes("pump_setting") && <div className="team-view-button">
                <a href="javascript:void(0)" className="btn btn-outline-light w-100px" onClick={() => onChangeSidebar("PumpSchedular", props.pump)}><span>Setting</span></a>
                {/* <div className=''>
                <span className="sub-text-sync">Last Update on : <br />{updatedTime}</span>
              </div> */}
              </div>}
              <div class="invest-data mt-2">
                <div class="invest-data-amount g-2">
                  <div class="invest-data-history">
                    <div class="title">Current Status</div>
                    <div class="amount">{props.pump.name} </div>
                    {lastPumpFeedbackTime ? <div class="amount">{lastPumpFeedbackTime} </div> : <p>Loading...</p>}
                  </div>
                </div>
              </div>
              {/* <div className='mt-2'>
              <span className="sub-text-mysync">Last <span className='fw-bold'>{lastPumpFeedbackName} {lastPumpFeedbackTime}</span></span>
            </div> */}
              {/* <div className='d-flex justify-content-between'>
              <span className="sub-text-sync">Refresh the button</span>
              <a href="javascript:void(0)" className="logo-link" onClick={() => syncRefresh(props.pump.id)}>
                <img className="img-fluid" src="./images/sync.png" srcSet="./images/sync.png" alt="sync" />
              </a>
            </div> */}
            </div>
          </div>
        </div>
      }

      <DeleteModal message="Warning: Deleting this Pump will effects the below following entities:"
        deleteResponse={deleteResponse}
        deleteEntity={deletePump}
        userId={userId} />
      <button
        id="modalDelete"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedDelete"
        style={{ display: 'none' }}
      >
        Open Modal 2
      </button>
      <button
        id="closeModalPump"
        type="button"
        className="btn btn-primary"
        data-bs-target="#assignedDelete"
        data-bs-dismiss="modal" aria-label="Close"
        style={{ display: 'none' }}
      >
        Close Modal 2
      </button>
    </>
  )
}

export default PumpRender